import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_URL } from "../../configs/EnvironmentConfig";

const initialState = {
  members: [],
  loading: false,
  error: null,
};

export const getMembers = createAsyncThunk("members/getMembers", async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/members`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateMember = createAsyncThunk(
  "members/updateMember",
  async ({ id, ...memberData }) => {
    try {
      const response = await axios.put(
        `${BACKEND_URL}/members/${id}`,
        memberData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addMember = createAsyncThunk(
  "members/addMember",
  async (memberData) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/members`, memberData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const uploadMemberImage = createAsyncThunk(
  "members/uploadMemberImage",
  async (formData) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/members/upload`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const removeMember = createAsyncThunk(
  "members/removeMember",
  async (id) => {
    try {
      await axios.delete(`${BACKEND_URL}/members/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload;
      })
      .addCase(getMembers.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Błąd przy pobieraniu danych członków.";
      })
      .addCase(addMember.fulfilled, (state, action) => {
        state.members.push(action.payload);
      })
      .addCase(addMember.rejected, (state, action) => {
        state.error = action.error.message || "Błąd przy dodawaniu członka.";
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        console.log("Zaktualizowano członka:", action.payload);
        state.members = state.members.map((member) =>
          member.id === action.payload.id ? action.payload : member
        );
      })
      .addCase(updateMember.rejected, (state, action) => {
        console.error("Błąd aktualizacji:", action.error);
        state.error =
          action.error.message || "Błąd przy aktualizacji danych członka.";
      })
      .addCase(uploadMemberImage.fulfilled, (state, action) => {
        const { memberId, filePath } = action.payload;
        const memberIndex = state.members.findIndex(
          (member) => member.id === memberId
        );
        if (memberIndex !== -1) {
          state.members[memberIndex].imageUrl = filePath;
        }
      })
      .addCase(uploadMemberImage.rejected, (state, action) => {
        state.error = action.error.message || "Błąd przy uploadzie zdjęcia.";
      })
      .addCase(removeMember.fulfilled, (state, action) => {
        state.members = state.members.filter(
          (member) => member.id !== action.payload
        );
      })
      .addCase(removeMember.rejected, (state, action) => {
        state.error = action.error.message || "Błąd przy usuwaniu członka.";
      });
  },
});

export default membersSlice.reducer;
