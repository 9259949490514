import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_URL } from "../../configs/EnvironmentConfig";

const initialState = {
  about: null,
  loading: false,
  error: null,
};

export const getAbout = createAsyncThunk("about/getAbout", async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/about`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateAbout = createAsyncThunk(
  "about/updateAbout",
  async ({ id, ...aboutData }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/about/${id}`, aboutData);
      return response.data;
    } catch (error) {
      console.error("Błąd podczas aktualizacji:", error.response || error);
      throw error;
    }
  }
);

const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAbout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAbout.fulfilled, (state, action) => {
        state.loading = false;
        state.about = action.payload;
      })
      .addCase(getAbout.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Błąd przy pobieraniu danych o nas";
      })
      .addCase(updateAbout.fulfilled, (state, action) => {
        const index = state.about.data.findIndex(
          (section) => section.id === action.payload.id
        );
        if (index !== -1) {
          state.about.data[index] = action.payload;
        }
      })
      .addCase(updateAbout.rejected, (state, action) => {
        state.error =
          action.error.message || "Błąd przy aktualizacji danych o nas";
      });
  },
});

export default aboutSlice.reducer;
