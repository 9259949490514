import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import usersReducer from "./slices/usersSlices";
import collectionsReducer from "./slices/collectionsSlices";
import newsReducer from "./slices/newsSlice";
import settingsReducer from "./slices/settingsSlices";
import slidesReducer from "./slices/slidesSlices";
import factsSlice from "./slices/factsSlice";
import aboutSlice from "./slices/aboutSlice";
import membersSlice from "./slices/membersSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    users: usersReducer,
    collections: collectionsReducer,
    settings: settingsReducer,
    sliderSettings: slidesReducer,
    news: newsReducer,
    facts: factsSlice,
    about: aboutSlice,
    members: membersSlice,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
