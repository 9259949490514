import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_URL } from "../../configs/EnvironmentConfig";

const initialState = {
  sliderSettings: [],
  loading: false,
  error: null,
};

export const fetchSliderSettings = createAsyncThunk(
  "sliderSettings/fetchSliderSettings",
  async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/slider-settings`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createOrUpdateSliderSetting = createAsyncThunk(
  "sliderSettings/createOrUpdateSliderSetting",
  async (sliderSettingData, { getState }) => {
    try {
      const token = getState().auth.token;
      const response = await axios.post(
        `${BACKEND_URL}/slider-settings`,
        sliderSettingData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteSliderSetting = createAsyncThunk(
  "sliderSettings/deleteSliderSetting",
  async (id, { getState }) => {
    try {
      const token = getState().auth.token;
      await axios.delete(`${BACKEND_URL}/slider-settings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return id;
    } catch (error) {
      throw error;
    }
  }
);

export const addSliderSetting = createAsyncThunk(
  "sliderSettings/addSliderSetting",
  async (newSlide, { getState }) => {
    try {
      const token = getState().auth.token;
      const response = await axios.post(
        `${BACKEND_URL}/slider-settings`,
        newSlide,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addNewSlide = createAsyncThunk(
  "sliderSettings/addNewSlide",
  async (_, { getState, dispatch }) => {
    const newSlide = {
      bg: null,
      text: "",
      title: "",
      buttonText: "",
      href: "",
    };

    return newSlide;
  }
);

export const updateSlide = createAsyncThunk(
  "sliderSettings/updateSlide",
  async ({ id, slideData }, { getState, dispatch }) => {
    try {
      const token = getState().auth.token;
      const response = await axios.put(
        `${BACKEND_URL}/slider-settings/${id}`,
        slideData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Odpowiedź z serwera:", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getSlideData = createAsyncThunk(
  "sliderSettings/getSlideData",
  async (id, { getState }) => {
    try {
      const token = getState().auth.token;
      const response = await axios.get(`${BACKEND_URL}/slider-settings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const sliderSettingsSlice = createSlice({
  name: "sliderSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliderSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSliderSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.sliderSettings = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];
      })
      .addCase(fetchSliderSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSliderSetting.fulfilled, (state, action) => {
        state.sliderSettings = state.sliderSettings.filter(
          (setting) => setting.id !== action.payload
        );
      })
      .addCase(createOrUpdateSliderSetting.fulfilled, (state, action) => {
        const index = state.sliderSettings.findIndex(
          (s) => s.id === action.payload.data.id
        );
        if (index !== -1) {
          state.sliderSettings[index] = action.payload.data;
        } else {
          state.sliderSettings.push(action.payload.data);
        }
      })
      .addCase(getSlideData.fulfilled, (state, action) => {
        state.currentSlide = action.payload;
      })
      .addCase(addSliderSetting.fulfilled, (state, action) => {
        state.sliderSettings.push(action.payload.data);
      })
      .addCase(addNewSlide.fulfilled, (state, action) => {
        state.sliderSettings.push(action.payload);
      })
      .addCase(updateSlide.fulfilled, (state, action) => {
        state.sliderSettings = action.payload;
      });
  },
});

export default sliderSettingsSlice.reducer;
