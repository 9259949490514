import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "set-password",
    path: `${AUTH_PREFIX_PATH}/setPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/set-password")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgotPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "default",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
    access: ["Admin"],
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() => import("../pages/Users")),
    access: ["Admin"],
  },
  {
    key: "user-details",
    path: `${APP_PREFIX_PATH}/users/profile`,
    component: React.lazy(() => import("../pages/UserProfile")),
    access: ["Admin", "Client"],
  },
  {
    key: "user-new",
    path: `${APP_PREFIX_PATH}/users/new`,
    component: React.lazy(() => import("../pages/UserAdd")),
    access: ["Admin"],
  },
  {
    key: "user-edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("../pages/UserEdit")),
    access: ["Admin"],
  },
  {
    key: "collection-form",
    path: `${APP_PREFIX_PATH}/collection/new`,
    component: React.lazy(() => import("../pages/CollectionAdd")),
    access: ["Admin"],
  },
  {
    key: "collection-edit",
    path: `${APP_PREFIX_PATH}/collection/edit/:id`,
    component: React.lazy(() => import("../pages/CollectionEdit")),
    access: ["Admin"],
  },
  {
    key: "collections",
    path: `${APP_PREFIX_PATH}/collections`,
    component: React.lazy(() => import("../pages/Collections")),
    access: ["Admin"],
  },
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    component: React.lazy(() => import("../pages/Settings")),
    access: ["Admin"],
  },
  {
    key: "settings-slides",
    path: `${APP_PREFIX_PATH}/settings-slides`,
    component: React.lazy(() => import("../pages/SettingsSlides")),
    access: ["Admin"],
  },
  {
    key: "slide-add",
    path: `${APP_PREFIX_PATH}/slide/add`,
    component: React.lazy(() => import("../pages/SlideAdd")),
    access: ["Admin"],
  },
  {
    key: "slide-edit",
    path: `${APP_PREFIX_PATH}/slide/edit/:slideId`,
    component: React.lazy(() => import("../pages/SlideEdit")),
    access: ["Admin"],
  },
  {
    key: "news",
    path: `${APP_PREFIX_PATH}/news`,
    component: React.lazy(() => import("../pages/News")),
    access: ["Admin"],
  },
  {
    key: "news-form",
    path: `${APP_PREFIX_PATH}/news/add`,
    component: React.lazy(() => import("../pages/NewsAdd")),
    access: ["Admin"],
  },
  {
    key: "news-edit",
    path: `${APP_PREFIX_PATH}/news/edit/:newsId`,
    component: React.lazy(() => import("../pages/NewsEdit")),
    access: ["Admin"],
  },
  {
    key: "facts",
    path: `${APP_PREFIX_PATH}/facts`,
    component: React.lazy(() => import("../pages/Facts")),
    access: ["Admin"],
  },
  {
    key: "about",
    path: `${APP_PREFIX_PATH}/about`,
    component: React.lazy(() => import("../pages/About")),
    access: ["Admin"],
  },
  {
    key: "members",
    path: `${APP_PREFIX_PATH}/members`,
    component: React.lazy(() => import("../pages/Members")),
    access: ["Admin"],
  },
];
