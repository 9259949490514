import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_URL } from "../../configs/EnvironmentConfig";

const initialState = {
  users: [],
  loading: false,
  error: null,
  isDeleting: false,
  deleteError: null,
  isBlocking: false,
  blockError: null,
  isUnblocking: false,
  unblockError: null,
};

export const getUsers = createAsyncThunk("users/", async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/users`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Błąd";
      });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const index = state.users.findIndex(
        (user) => user.id === action.payload.id
      );
      if (index !== -1) {
        state.users[index] = action.payload;
      }
    });
  },
});

export const getUserById = createAsyncThunk(
  "users/getUserById",
  async (userId, { getState }) => {
    const token = getState().auth.token;
    const response = await axios.get(`${BACKEND_URL}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

export const blockUser = createAsyncThunk(
  "users/blockUser",
  async (userId, { getState }) => {
    try {
      const token = getState().auth.token;
      await axios.put(
        `${BACKEND_URL}/users/${userId}`,
        { userStatus: "Blocked" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return userId;
    } catch (error) {
      throw error;
    }
  }
);

export const unblockUser = createAsyncThunk(
  "users/unblockUser",
  async (userId, { getState }) => {
    try {
      const token = getState().auth.token;
      await axios.put(
        `${BACKEND_URL}/users/${userId}`,
        { userStatus: "Active" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return userId;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (userId, { getState }) => {
    try {
      const token = getState().auth.token;
      await axios.delete(`${BACKEND_URL}/users/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return userId;
    } catch (error) {
      throw error;
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ userId, userData }, { getState }) => {
    const token = getState().auth.token;
    const response = await axios.put(
      `${BACKEND_URL}/users/${userId}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

export default usersSlice.reducer;
