import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_URL } from "../../configs/EnvironmentConfig";

const initialState = {
  news: [],
  loading: false,
  error: null,
  selectedNews: null,
};

export const getNews = createAsyncThunk("news/getNews", async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/news`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const createNews = createAsyncThunk(
  "news/createNews",
  async (newsData) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/news`, newsData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateNews = createAsyncThunk(
  "news/updateNews",
  async ({ id, newsData }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/news/${id}`, newsData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteNews = createAsyncThunk("news/deleteNews", async (id) => {
  try {
    await axios.delete(`${BACKEND_URL}/news/${id}`);
    return id;
  } catch (error) {
    throw error;
  }
});

export const getNewsById = createAsyncThunk("news/getNewsById", async (id) => {
  try {
    console.log("Pobieranie newsa o ID:", id);
    const response = await axios.get(`${BACKEND_URL}/news/${id}`);
    console.log("Odpowiedź dla newsa o ID:", id, response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNewsById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedNews = action.payload;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];
      })
      .addCase(getNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Błąd przy pobieraniu newsów";
      })
      .addCase(createNews.fulfilled, (state, action) => {
        state.news.push(action.payload);
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        const index = state.news.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.news[index] = action.payload;
        }
      })
      .addCase(deleteNews.fulfilled, (state, action) => {
        state.news = state.news.filter((item) => item.id !== action.payload);
      });
  },
});

export default newsSlice.reducer;
