import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_URL } from "../../configs/EnvironmentConfig";
import { idea } from "react-syntax-highlighter/dist/cjs/styles/hljs";

const initialState = {
  collections: [],
  loading: false,
  error: null,
};

export const getCollections = createAsyncThunk(
  "collections/getCollections",
  async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/collections`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCollectionById = createAsyncThunk(
  "collections/getCollectionById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/collections/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createCollection = createAsyncThunk(
  "collections/createCollection",
  async (collectionData) => {
    const response = await axios.post(
      `${BACKEND_URL}/collections`,
      collectionData
    );
    return response.data;
  }
);

export const updateCollection = createAsyncThunk(
  "collections/updateCollection",
  async ({ id, collectionData }) => {
    try {
      const response = await axios.put(
        `${BACKEND_URL}/collections/${id}`,
        collectionData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCollection = createAsyncThunk(
  "collections/deleteCollection",
  async (id) => {
    try {
      await axios.delete(`${BACKEND_URL}/collections/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

export const blockCollection = createAsyncThunk(
  "collections/blockCollection",
  async (id, { getState }) => {
    try {
      const token = getState().auth.token;
      await axios.put(
        `${BACKEND_URL}/collections/${id}`,
        { collectionStatus: "Zablokowana" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return id;
    } catch (error) {
      throw error;
    }
  }
);

export const unblockCollection = createAsyncThunk(
  "collections/unblockCollection",
  async (id, { getState }) => {
    try {
      const token = getState().auth.token;
      await axios.put(
        `${BACKEND_URL}/collections/${id}`,
        { collectionStatus: "Aktywna" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return id;
    } catch (error) {
      throw error;
    }
  }
);

const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCollections.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCollections.fulfilled, (state, action) => {
        state.loading = false;
        state.collections = action.payload.data;
      })
      .addCase(getCollections.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Błąd przy pobieraniu zbiórek";
      });
    builder.addCase(updateCollection.fulfilled, (state, action) => {
      const index = state.collections.findIndex(
        (collection) => collection.id === action.payload.id
      );
      if (index !== -1) {
        state.collections[index] = action.payload;
      }
    });
  },
});

export default collectionsSlice.reducer;
