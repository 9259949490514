import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_URL } from "../../configs/EnvironmentConfig";

const initialState = {
  facts: [],
  loading: false,
  error: null,
};

export const getFacts = createAsyncThunk("facts/getFacts", async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/facts`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const getFactById = createAsyncThunk(
  "facts/getFactById",
  async (id, { getState }) => {
    try {
      const token = getState().auth.token;
      const response = await axios.get(`${BACKEND_URL}/facts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createFacts = createAsyncThunk(
  "facts/createFacts",
  async (factData) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/facts`, factData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteFacts = createAsyncThunk(
  "facts/deleteFacts",
  async (id, { getState }) => {
    try {
      const token = getState().auth.token;
      await axios.delete(`${BACKEND_URL}/facts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return id;
    } catch (error) {
      throw error;
    }
  }
);

export const updateFacts = createAsyncThunk(
  "facts/updateFacts",
  async ({ id, factData }, { getState }) => {
    try {
      const token = getState().auth.token;
      const response = await axios.put(`${BACKEND_URL}/facts/${id}`, factData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const factsSlice = createSlice({
  name: "facts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFacts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFacts.fulfilled, (state, action) => {
        state.loading = false;
        state.facts = action.payload.data;
      })
      .addCase(getFacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Błąd przy pobieraniu faktów";
      });

    builder.addCase(getFactById.fulfilled, (state, action) => {
      const index = state.facts.findIndex(
        (fact) => fact.id === action.payload.id
      );
      if (index !== -1) {
        state.facts[index] = action.payload;
      } else {
        state.facts.push(action.payload);
      }
    });

    builder.addCase(createFacts.fulfilled, (state, action) => {
      state.facts.push(action.payload);
    });

    builder.addCase(updateFacts.fulfilled, (state, action) => {
      const index = state.facts.findIndex(
        (fact) => fact.id === action.payload.id
      );
      if (index !== -1) {
        state.facts[index] = action.payload;
      }
    });

    builder.addCase(deleteFacts.fulfilled, (state, action) => {
      state.facts = state.facts.filter((fact) => fact.id !== action.payload.id);
    });
  },
});

export default factsSlice.reducer;
