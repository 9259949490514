import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_URL } from "../../configs/EnvironmentConfig";

const initialState = {
  settings: null,
  loading: false,
  error: null,
};

export const getSettings = createAsyncThunk(
  "settings/getSettings",
  async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/settings`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateSettings = createAsyncThunk(
  "settings/updateSettings",
  async (settingsData, { getState }) => {
    try {
      const token = getState().auth.token;
      const { id, ...updateData } = settingsData;
      const response = await axios.put(
        `${BACKEND_URL}/settings/2`,
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload.data;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Błąd przy pobieraniu ustawień";
      });

    builder.addCase(updateSettings.fulfilled, (state, action) => {
      state.settings = action.payload.data;
    });
  },
});

export default settingsSlice.reducer;
